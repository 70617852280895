import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
//import { CustomEventArgs, OutboundLink, OutboundLinkProps, trackCustomEvent } from 'gatsby-plugin-google-analytics';

import Theme, { theme } from '@pagerland/themes/src/Corporate';
import {
  //Navbar,
  //Welcome,
  //Offer,
  //Goals,
  //Mission,
  //Cta,
  //Projects,
  Team,
  //Testimonials,
  Pricing,
  //Copyright,
  Newsletter,
  //Contact,
  //Technologies,
} from '@pagerland/themes/src/Corporate/containers';
import Navbar from '../containers/Navbar';
import Offer from '../containers/Offer';
import Welcome from '../containers/Welcome';
import Technologies from '../containers/Technologies';
import Copyright from '../containers/Copyright';
import Projects from '../containers/Projects';
import Contact from '../containers/Contact';
//import data from '@pagerland/themes/src/Corporate/data';

import preview from '@pagerland/themes/src/Corporate/assets/preview.jpg';

import SEO from '../components/SEO';
import ContactUs from '../containers/ContactUs';

import { HomePageQuery } from '../types/graphql-types';
import Ctx from '../types/page-context';
import Box from '@pagerland/common/src/components/Box';
import Container from '@pagerland/common/src/components/Container';
import { string } from 'yup';

const WrapperProps = {
  pt: {
    _: 56,
    md: 64,
    lg: 192,
  },
  pb: {
    _: 56,
    md: 64,
    lg: 96,
  },
};
const ContainerProps = {
  textAlign: 'left'
};

type Props = PageProps<HomePageQuery, Ctx>;
const HomeTemplate: React.FC<Props> = ({data, pageContext}) => {
  //console.warn(pageContext);
  const {title, description, id, slug, templateKey, welcome, whatWeDo, projects} = data.info.frontmatter;
  const {siteUrl, lang} = pageContext;
  
  const lngs = data.otherLanguages.edges;
  const otherLanguages:{lang: string, slug: string}[] = [];
  lngs.forEach(lng => {
    const {lang, slug} = lng.node.frontmatter;
    otherLanguages.push({lang: lang, slug: lang+"/"+slug});
  });

  const home = (
    <Theme>
      <Helmet>
        {/*<link href={theme.typography.googleFont} rel="stylesheet" />*/}
        <link rel="preload" as="font" href={theme.typography.googleFont} type="font/ttf" />
        <meta name="theme-color" content={theme.colors.primary} />
        <meta property="og:image" content={`${pageContext.siteUrl}${preview}`} />
        {otherLanguages.map(({lang, slug}) =>
          <link rel="alternate" hrefLang={lang} href={slug}/>
        )}
        {/*
        <script type="text/javascript">
          (function() {
            window.sib = { equeue: [], client_key: "fi6g4139ttmnzeizxs7705tv"};
            // OPTIONAL: email to identify request
            // window.sib.email_id = 'example@domain.com';
            // OPTIONAL: to hide the chat on your script uncomment this line (0 = chat hidden; 1 = display chat) 
            // window.sib.display_chat = 0;
            // window.sib.display_logo = 0;
            // OPTIONAL: to overwrite the default welcome message uncomment this line
            // window.sib.custom_welcome_message = 'Hello, how can we help you?';
            // OPTIONAL: to overwrite the default offline message uncomment this line
            // window.sib.custom_offline_message = 'We are currently offline. In order to answer you, please indicate your email in your messages.';
            window.sendinblue = {}; for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) { (function(k) { window.sendinblue[k] = function(){ var arg = Array.prototype.slice.call(arguments); (window.sib[k] || function() { var t = {}; t[k] = arg; window.sib.equeue.push(t);})(arg[0], arg[1], arg[2]);};})(j[i]);}var n = document.createElement("script"),i = document.getElementsByTagName("script")[0]; n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
          })();
        </script>
        */}
      </Helmet>
      <SEO title="Koent" lang={pageContext.lang} />

      <Navbar location={'/'} />
      <Welcome name="welcome" data={welcome} />
      <Offer name="whatWeDo" data={whatWeDo} />
      {/*<Offer name="offerB2B" />*/}
      {/*<Goals name="goals" />*/}
      {/*<Mission name="mission" />*/}
      {/*<Cta name="cta1" {...data.supportCta} />*/}
      <Projects name="projects" data={projects} />
      {/*<Technologies name="technologies" />*/}
      {/*<Team name="team" />*/}
      {/*<Testimonials name="testimonials" />*/}
      {/*<Cta name="cta2" {...data.contactCta} />*/}
      {/*<Pricing name="pricing" />*/}
      <Contact name="contact" />
      <ContactUs name="contactUs" />
      {/*<Newsletter name="newsletter" />*/}
      <Copyright />
    </Theme>
  );

  return home;
};

export default HomeTemplate;

export const pageQuery = graphql`
  query HomePage($id: String!, $idFrontmatter: String!, $lang: String!) {
    info: mdx(id: { eq: $id }) {
      ...HomePageFragment
    }
    otherLanguages: allMdx(filter: {frontmatter: {id: {eq: $idFrontmatter}, lang: {ne: $lang}}}) {
      edges {
        node {
          frontmatter {
            lang
            slug
          }
        }
      }
    }
  }
`;

export const homeFragment = graphql`
  fragment HomePageFragment on Mdx {
    frontmatter {
      id
      title
      templateKey
      description
      slug
      ...ComponentsDataWelcome
      ...ComponentsDataWhatWeDo
      ...ComponentsDataProjects
    }
  }
`;
