import React from 'react';
import PropTypes from 'prop-types';

import Box from '@pagerland/common/src/components/Box';
import Fade from 'react-reveal/Fade';
import Typography from '@pagerland/common/src/components/Typography';
import Container from '@pagerland/common/src/components/Container';
import Grid from '@pagerland/common/src/components/Grid';
//import Button from '@pagerland/common/src/components/Button';
import Avatar from '../../components/Avatar';
import Card from '@pagerland/common/src/components/Card';

//import Img from '@pagerland/common/src/components/Img';
import Img from '../../components/Img';
//import ArrowRight from '@pagerland/icons/src/line/ArrowRight';
import styled from 'styled-components';
//import { useProjects } from '../../staticQueries/useMdxInfo';
import { getImage } from 'gatsby-plugin-image';
import { MdxFrontmatterProjects } from '../../types/graphql-types';

const ProjectCard = styled(Card)`
  padding: 0!important;
  margin: 0!important;
  &:hover picture img {
    transform:scale(1.05)!important;
  }
  picture img {
    transition: all .2s ease-in-out!important;
  }
`;
const ProjectWrapperInnerCard = styled(Card)`
  backgroundColor: #2997C3AA;
  width: calc(100% - 40px);
  height: calc(1 / 3);
  margin: auto;
  display: flex;
  flexDirection: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  zIndex: 2;
  padding: 0;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

const StyledImg = styled(Img)`
  position: relative!important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  object-fit: fill;
  user-select: none;
  opacity: ${props => (props.theme.mode === 'dark' ? 0.6 : 0.8)};
`;

interface Props {
  name: string;
  data: MdxFrontmatterProjects;
  WrapperProps?: any;
  ContainerProps?: any;
  ImageProps?: any;
  ImageWrapperProps?: any;
  TemplateProps: any;
  CaptionProps: any;
  SubtitleProps?: any;
  TitleProps?: any;
  TextProps?: any;
  GridProps?: any;
  //CtaProps?: any;
  ProjectCardProps?: any;
  ProjectWrapperProps?: any;
  ProjectWrapperInnerProps?: any;
  ProjectWrapperInnerWrapperProps?: any;
  ProjectWrapperInnerWrapperTagProps?: any;
  AvatarProps?: any;
  NameProps?: any;
  PositionProps?: any;
};

const Projects: React.FC<Props> = ({
  name,
  data,
  //cta,
  WrapperProps,
  ContainerProps,
  CaptionProps,
  SubtitleProps,
  ImageWrapperProps,
  ImageProps,
  TitleProps,
  TextProps,
  GridProps,
  //CtaProps,
  ProjectWrapperProps,
  ProjectWrapperInnerProps,
  ProjectWrapperInnerWrapperProps,
  ProjectWrapperInnerWrapperTagProps,
  ProjectCardProps,
  AvatarProps,
  NameProps,
  PositionProps,
  TemplateProps,
}) => {
  //const { subtitle, title, img, text, portfolio } = useProjects();
  const { subtitle, title, img, text, portfolio } = data;
  const imgProps = getImage(img.src.childImageSharp.gatsbyImageData);
  return (
    <Box name={name} id={name} {...WrapperProps}>
      <Container {...ContainerProps}>
        <Grid {...TemplateProps}>
          <Box {...ImageWrapperProps}>
            <Fade cascade duration={600}>
              <Img
                image={imgProps}
                alt ={img.alt}
                {...ImageProps}
              />
            </Fade>
          </Box>
          <Box {...CaptionProps}>
            <Fade bottom cascade duration={600}>
              <Typography {...SubtitleProps}>{subtitle}</Typography>
              <Typography {...TitleProps}>{title}</Typography>
              <Typography {...TextProps}>{text}</Typography>
              {/*<Button {...CtaProps} {...cta}>
                {cta.label}
              </Button>*/}
            </Fade>
          </Box>
          <Grid {...GridProps}>
            {portfolio?.map((project, key) => {
              console.warn(project);
              const avatar = getImage(project?.avatar?.src?.childImageSharp?.gatsbyImageData);
              const avatarAlt = `${project?.name} - ${project?.position}`;
              //const avatar = {src: project.avatar.src.childImageSharp.gatsbyImageData.base64, srcSet: project.avatar.src.childImageSharp.gatsbyImageData.srcSetWebp, alt: `${project.name} - ${project.position}`};
              return (
                <Fade key={key} bottom cascade duration={600} delay={key * 100}>
                    <a target="_blank" href={project?.url??"https://koent.it"}>
                    <ProjectCard {...ProjectCardProps}>
                      {/*<Box {...{...ProjectWrapperProps, backgroundImage: project.avatar.src.childImageSharp.gatsbyImageData.placeholder.fallback}}>*/}
                          {/*<Wrapper>*/}
                            <StyledImg {...AvatarProps} 
                              {...project?.avatar?.src?.childImageSharp?.gatsbyImageData} 
                              image={avatar}
                              alt ={avatarAlt}
                            />
                          {/*</Wrapper>*/}
                          <Box {...ProjectWrapperProps}>
                            <ProjectWrapperInnerCard {...ProjectWrapperInnerProps}>
                              <Typography {...NameProps}>{project.name}</Typography>
                              <Typography {...PositionProps}>{project.role} | {project.position}</Typography>
                              {/*<Typography {...PositionProps}>{project.activities.join(" | ")}</Typography>*/}
                              <Box {...ProjectWrapperInnerWrapperProps}>
                                {project.activities.map((activity, key) => (<Box key={key} {...ProjectWrapperInnerWrapperTagProps}>{activity}</Box>))}
                              </Box>
                            </ProjectWrapperInnerCard>
                          </Box>
                    </ProjectCard>
                    </a>
                </Fade>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

Projects.defaultProps = {
  WrapperProps: {
    py: {
      _: 56,
      md: 64,
      lg: 96,
    },
  },
  ContainerProps: {

  },
  TemplateProps: {
    gridTemplateColumns: {
      _: '1fr',
      lg: '544px auto',
    },
    gridTemplateAreas: {
      _: '"image" "caption" "projects"',
      lg: '"caption image" "projects projects"',
    },
    gridGap: {
      _: '32px',
      lg: '64px',
    },
  },
  ImageWrapperProps: {
    gridArea: 'image',
  },
  ImageProps: {
    maxWidth: '100%',
  },
  CaptionProps: {
    gridArea: 'caption',
    alignSelf: 'center',
    mb: {
      _: 4,
      md: 5,
    },
    mx: 'auto',
    maxWidth: 736,
  },
  SubtitleProps: {
    as: 'h3',
    variant: 'h5',
    color: 'brand',
  },
  TitleProps: {
    as: 'h2',
    variant: 'h2',
    color: 'invert',
    mb: 3,
  },
  TextProps: {
    mb: 4,
  },
  GridProps: {
    gridArea: 'projects',
    textAlign: 'center',
    gridTemplateColumns: {
      _: '1fr',
      md: 'repeat(3, 1fr)',
      //lg: 'repeat(4, 1fr)',
      lg: 'repeat(4, 1fr)',
    },
    gridColumnGap: '16px',
    gridRowGap: {
      _: '24px',
      md: '24px',
    },
  },
  ProjectCardProps: {
    textAlign: 'center',
    width: {
      _: '50%',
      md: '60%',
      lg: '100%',
    },
    height: '100%',
    minHeight: '100px',
    mb: 2,
  },
  AvatarProps: {
    
  },
  ProjectWrapperProps: {
    pt: {
      _: 0,
      lg: 0,
    },
    pb: {
      _: 0,
      lg: 0,
    },
    position: {
      _: 'absolute!important',
      lg: 'absolute!important',
    },
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    textAlign: "center",
  },
  ProjectWrapperInnerProps: {
    backgroundColor: "#2997C3AA",
    width: '90%',
    height: '40%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2,
  },
  ProjectWrapperInnerWrapperProps: {
    width: '100%',
    margin: 0,
    marginTop: 0,
    paddingBottom: '5px',
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'row',
    zIndex: 2,
  },
  ProjectWrapperInnerWrapperTagProps: {
    fontSize: "small",
    margin: "auto",
    backgroundColor: "#2CABCE",
    pointerEvents: "none",
    border: "none",
    color: 'black',
    padding: "0px 5px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    borderRadius: "100px",
  },
  NameProps: {
    variant: 'h6',
    color: 'invert',
    whiteSpace: 'nowrap',
  },
  PositionProps: {
    color: 'invert'
    //mb: 2,
  },
  /*CtaProps: {
    variant: 'link',
    color: 'invert',
    icon: ArrowRight,
  },*/
};

export default Projects;
