import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

import Container from '@pagerland/common/src/components/Container';
import Typography from '@pagerland/common/src/components/Typography';
import Box from '@pagerland/common/src/components/Box';

import { useHeaderFooter } from '../../staticQueries/useMdxInfo';
import textToMultiline from '@pagerland/common/src/utils/textToMultiline';

const StyledWrapper = styled(Box)`
  background-color: ${props => props.theme.colors.muted};
  color: ${props =>
    props.theme.mode === 'dark'
      ? transparentize(0.7, props.theme.colors.gray[4])
      : props.theme.colors.gray[4]};
`;

interface Props {
  WrapperProps?: {
    [x:string]: any;
  };
  WrapperImagesProps?: {
    [x:string]: any;
  };
  IFrameProps?: {
    [x:string]: any;
  };
  TextProps?: {
    [x:string]: any;
  };
}

const Copyright: React.FC<Props> = ({ WrapperProps, WrapperImagesProps, IFrameProps, TextProps}) => {
  const { copyright } = useHeaderFooter();
  return (
    <StyledWrapper {...WrapperProps}>
      <StyledWrapper {...WrapperImagesProps}>
        <iframe style={{...IFrameProps}} width={"100vw"} height={"100vw"} src="https://shareables.clutch.co/share/badges/1782716/2432?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch Software Developers Italy 2023"></iframe>
        <iframe style={{...IFrameProps}} width={"100vw"} height={"100vw"} src="https://shareables.clutch.co/share/badges/1782716/31442?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch Flutter Developers Italy 2023"></iframe>
        <iframe style={{...IFrameProps}} width={"100vw"} height={"100vw"} src="https://shareables.clutch.co/share/badges/1782716/1490?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch App Development Company Italy 2023"></iframe>
      </StyledWrapper>
      <Container>
        <Typography {...TextProps}>{textToMultiline(copyright)}</Typography>
      </Container>
    </StyledWrapper>
  );
}

Copyright.defaultProps = {
  TextProps: {
    variant: 'body2',
    textAlign: 'center',
  },
  WrapperProps: {
    py: 4,
  },
  WrapperImagesProps: {
    float: 'right',
    width: '100%',
    display: 'inline-block'
  },
  IFrameProps: {
    paddingRight: 2,
    float: 'right',
    border: 'none',
  }
};

export default Copyright;
