import styled from 'styled-components';
import { base, theme } from '@pagerland/common/src/utils';
import { GatsbyImage } from "gatsby-plugin-image";
import React from 'react';

/**
 * Use prestyled images
 */
const Img = styled(GatsbyImage)`
  ${base};
  ${theme('Img')};
`;

export default Img;
