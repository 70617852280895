import styled from 'styled-components';
import { base, theme } from '@pagerland/common/src/utils';
import React from 'react';

/**
 * Use prestyled images
 */
const Svg = styled.img`
  ${base};
  ${theme('Img')};
`;

export default Svg;
